import Web3 from 'web3';
import NFTCollection from '../resources/contracts/contract.json'
import Token from '../resources/contracts/token.json'

class ContractService {

  constructor(web3, account) {
    this.web3 = web3;
    this.account = account;
    this.nftContract = new web3.eth.Contract(NFTCollection.abi, NFTCollection.address); // Replace with actual address
    this.tokenContract = new web3.eth.Contract(Token.abi, Token.address); // Replace with actual address
  }

  debug(message){
    //console.log("["+new Date().toDateString()+"] - "+message);
  }

  async getTokenInfoFromReceipt(receiptToken) {
    const tokenUri = await this.nftContract.methods.tokenMetadata(receiptToken).call();
    return tokenUri;
  }

  async getEthBalanceFromContract(){
    this.debug("getEthBalanceFromContract");
    let balanceWei = await this.web3.eth.getBalance(this.nftContract._address);
    return this.web3.utils.fromWei(balanceWei, 'ether');
    //await this.getEthBalance(this.nftContract._address);
  }

  async getEthBalance(address){
    this.debug("getEthBalance");
    return await this.web3.eth.getBalance(address);
  }

  async getTokenBalance() {
    this.debug("getTokenBalance");
    return await this.getTokenBalanceByAddress(this.account);
  }
  
  async getTokenBalanceFromContract(){
    this.debug("getTokenBalanceFromContract");
    return await this.tokenContract.methods.balanceOf(this.nftContract._address).call();
    //await this.getTokenBalanceByAddress(this.nftContract.address);
  }

  async getTokenBalanceByAddress(address) {
    this.debug("getTokenBalanceByAddress");
    return this.tokenContract.methods.balanceOf(address).call();
  }

  async getTotalSupply(){
    this.debug("getTotalSupply");
    return await this.nftContract.methods.totalSupply().call();;
  }

  async productIsAvailable(product_id, startDate, hours)
  {
    try{
    let timeStampStart = new Date(startDate).getTime()/1000;
    let timeStampEnd = timeStampStart + (hours * 60 * 60 );
    
    let data = await this.nftContract.methods.isProductAvailable(product_id, timeStampStart, timeStampEnd).call();
    return data;
    }catch(error)
    {
      if(error.data && error.data.message)
        console.log(this.createErrorDataMessage(error.data.message));
      else
        console.log(this.createErrorDataMessage(error.toString()));
    }
  }

  async getNFTs() {
    this.debug("getNFTs");
    const balance = await this.nftContract.methods.balanceOf(this.account).call();
    let nfts = [];
    for (let i = 0; i < balance; i++) {
      const tokenId = await this.nftContract.methods.tokenOfOwnerByIndex(this.account, i).call();
      const tokenHash = await this.nftContract.methods.requestIdFromTokenId(tokenId).call();
      const tokenURI = await this.nftContract.methods.tokenURI(tokenId).call();
      nfts.push({hash:tokenHash, uri:tokenURI});
    }
    return nfts;
  }

  async getNFTsByProductId(productId) {
    this.debug("getNFTsByProductId");
    const nftIds = await this.nftContract.methods.getNFTsByProductId(productId).call();
    let nftsToReturn = [];
    for (let i = 0; i < nftIds.length; i++) {
      const tokenId = nftIds[i];
      const tokenURI = await this.nftContract.methods.tokenURI(tokenId).call();
      nftsToReturn.push({ uri: tokenURI });
    }
    return nftsToReturn;
  }

  async isOwner(address) {
    this.debug("getOwner");
    
    let addressOwner = await this.nftContract.methods.owner().call();
    return addressOwner.toLowerCase() == address.toLowerCase();
    
    ///Esto es provisional, porque no sé porque narices isOwner no está funcionando correctamente

    /*
    let result =  await this.nftContract.methods.isOwner().call();
    return result;
    */
  }

  createSuccessDataMessage(data){
    return {success:true, message:"", data:data};
  }

  createErrorDataMessage(message){
    return {success:false, message:message, data:null};
  }

  async withdraw()
  {
    this.debug("withdraw");
    const gasPrice = await this.web3.eth.getGasPrice();
    await this.nftContract.methods.withdraw().send({ from: this.account, gasPrice: gasPrice, value: 0});
  }

  async withdrawToken()
  {
    this.debug("withdrawToken");
    const gasPrice = await this.web3.eth.getGasPrice();
    try{
    await this.nftContract.methods.withdrawTokens().send({ from: this.account, gasPrice: gasPrice, value: 0});
    }catch(error)
    {
      debugger;
      console.log(error);
      throw error;
    }
  }

  async areTokensAllowance(numberOfTokens){
    const allowance = await this.tokenContract.methods.allowance(this.account, NFTCollection.address).call();
    //alert("hay:"+allowance+" tokens");
    return (parseInt(allowance) >= parseInt(numberOfTokens));
  }

  async approveTokenTransfer(cost){
    
    //const gasPrice =  this.web3.utils.toWei('20', 'gwei');
    //debugger;
    const gasPrice = await this.web3.eth.getGasPrice();
    //.getGasPrice();
    //gasPrice = gasPrice.mul(2); ///Estoy utilizando la tarifa de gas más alta para que me cogan lo antes posible.
    let data = null;
    try{
      data = await this.tokenContract.methods.approve(NFTCollection.address, cost).send({ from: this.account, gasPrice: gasPrice, value: 0});    
    }catch(error)
    {
      console.log(error);
      return null;
    }
    finally{
      return data;
    }
  }

  async buyNFTAsync(productId, startDateTime, numberHours, priceCost, useTokens)
  {
    this.debug("buyNFTWithEthersAsync");
    let weiValues = (useTokens) ? 0 : this.web3.utils.toWei(priceCost.toString(), 'ether');
    let cost = (useTokens) ? priceCost : weiValues;
    let timeStampStart = new Date(startDateTime).getTime()/1000;
    let timeStampEnd = timeStampStart + (numberHours * 60 * 60 );

    let message = null;
    try{
      
      const gasPrice = await this.web3.eth.getGasPrice();
      //const gasPrice =  this.web3.utils.toWei('20', 'gwei');
      
      /*
      if(useTokens)
        await this.tokenContract.methods.approve(NFTCollection.address, cost).send({ from: this.account, gasPrice: gasPrice, value: 0});    
      */
      
      let data = await this.nftContract.methods.mint(productId, "UTC", timeStampStart, timeStampEnd, cost, useTokens).send({ from: this.account, gasPrice: gasPrice, value: weiValues });    
    
      message = (data && data.events && data.events.RequestedTokenIdMinted && data.events.RequestedTokenIdMinted.returnValues && data.events.RequestedTokenIdMinted.returnValues.requestId)
              ? this.createSuccessDataMessage(data.events.RequestedTokenIdMinted.returnValues.requestId)
              : this.createErrorDataMessage("Error al intentar crear el NFT.");
    }catch(error)
    {
      if(error.data && error.data.message)
        message = this.createErrorDataMessage(error.data.message);
      else
        message = this.createErrorDataMessage(error.toString());
    }
    finally{
      return message;
    }
  }
}

export default ContractService;
